<ng-container *transloco="let t; read: 'import-cbl-modal'">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{t('title')}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body scrollable-modal">
    <div class="row g-0" style="min-width: 135px;">
      <app-step-tracker [steps]="steps" [currentStep]="currentStepIndex"></app-step-tracker>
    </div>

    <!-- This is going to need to have a fixed height with a scrollbar-->
    <div>
      <div class="row g-0" *ngIf="currentStepIndex === Step.Import">
        <p>{{t('import-description')}}</p>
        <form [formGroup]="uploadForm" enctype="multipart/form-data">
          <file-upload formControlName="files"></file-upload>
        </form>
      </div>

      <ng-container *ngIf="currentStepIndex === Step.Validate">
        <p>{{t('validate-description')}}</p>
        <div class="row g-0">
          <div ngbAccordion #accordion="ngbAccordion">
            @for(fileToProcess of filesToProcess; track fileToProcess.fileName) {
                <div ngbAccordionItem *ngIf="fileToProcess.validateSummary as summary">
                  <h5 ngbAccordionHeader>
                    <button ngbAccordionButton>
                      <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                    </button>
                  </h5>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-container [ngTemplateOutlet]="validationList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStepIndex === Step.DryRun">
        <div class="row g-0">
          <p>{{t('dry-run-description')}}</p>

          <div ngbAccordion #a="ngbAccordion">
            @for(fileToProcess of filesToProcess; track fileToProcess.fileName) {
              <div ngbAccordionItem *ngIf="fileToProcess.dryRunSummary as summary">
                <h5 ngbAccordionHeader>
                  <button ngbAccordionButton>
                    <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                  </button>
                </h5>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-container [ngTemplateOutlet]="resultsList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStepIndex === Step.Finalize">
        <div class="row g-0">
          <div ngbAccordion #a="ngbAccordion">
            @for(fileToProcess of filesToProcess; track fileToProcess.fileName) {
              <div ngbAccordionItem *ngIf="fileToProcess.finalizeSummary as summary">
                <h5 ngbAccordionHeader>
                  <button ngbAccordionButton>
                    <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                  </button>
                </h5>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-container [ngTemplateOutlet]="resultsList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #validationList let-summary="summary">
      @if (summary.results.length > 0) {
        <div class="justify-content-center col">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <i class="fa-solid fa-triangle-exclamation" style="font-size: 24px" aria-hidden="true"></i>
            </div>
            <div class="flex-grow-1 ms-3">
              {{t('validate-warning')}}
            </div>
          </div>
        </div>
        <ol class="list-group list-group-numbered list-group-flush" >
          <li class="list-group-item no-hover" *ngFor="let result of summary.results"
              [innerHTML]="result | cblConflictReason | safeHtml">
          </li>
        </ol>
      }
      @else {
        <div class="justify-content-center col">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <i class="fa-solid fa-circle-check" style="font-size: 24px" aria-hidden="true"></i>
            </div>
            <div class="flex-grow-1 ms-3">
              {{t('validate-no-issue-description')}}
            </div>
          </div>
        </div>
      }
    </ng-template>

    <ng-template #resultsList let-summary="summary">
      <ul class="list-group list-group-flush">
        @for(result of summary.results; track result.order) {
          <li class="list-group-item no-hover"
              innerHTML="{{result.order + 1}}. {{result | cblConflictReason | safeHtml}}"></li>
        }
      </ul>
    </ng-template>

    <ng-template #heading let-filename="filename" let-summary="summary">
      @switch (summary.success) {
        @case (CblImportResult.Success) {
          <span class="badge heading-badge bg-primary me-1">{{summary.success | cblImportResult}}</span>
        }
        @case (CblImportResult.Fail) {
          <span class="badge heading-badge bg-danger me-1">{{summary.success | cblImportResult}}</span>
        }
        @case (CblImportResult.Partial) {
          <span class="badge heading-badge bg-warning me-1">{{summary.success | cblImportResult}}</span>
        }
      }
      <span>{{filename}}<span *ngIf="summary.cblName">: ({{summary.cblName}})</span></span>
    </ng-template>


  </div>
  <div class="modal-footer">
    <form [formGroup]="cblSettingsForm" class="row align-items-center">
      <div class="col-auto">
        <div class="form-check form-switch">
          <input type="checkbox" id="settings-comicvine-mode" role="switch" formControlName="comicVineMatching" class="form-check-input"
                 aria-labelledby="auto-close-label">
          <label class="form-check-label" for="settings-comicvine-mode">{{t('comicvine-parsing-label')}}</label>
        </div>
      </div>
    </form>
    <!-- Spacer -->
    <div class="col" aria-hidden="true"></div>
    <div class="col-auto">
      <a class="btn btn-icon" [href]="WikiLink.ReadingListCBL" target="_blank" rel="noopener noreferrer">Help</a>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-secondary" (click)="close()">{{t('close')}}</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="prevStep()" [disabled]="!canMoveToPrevStep()">{{t('prev')}}</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!canMoveToNextStep()">{{t(NextButtonLabel)}}</button>
    </div>
  </div>



</ng-container>
